@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@font-face {
  font-family: 'Ubuntu';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Ubuntu.woff') format('woff'), url('../fonts/Ubuntu.ttf') format('truetype'), url('../fonts/Ubuntu.svg#Ubuntu-Bold') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Ubuntu-Regular.eot');
  src: url('../fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Ubuntu-Regular.woff') format('woff'), url('../fonts/Ubuntu-Regular.ttf') format('truetype'), url('../fonts/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Medium';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Ubuntu-Medium.eot');
  src: url('../fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Ubuntu-Medium.woff') format('woff'), url('../fonts/Ubuntu-Medium.ttf') format('truetype'), url('../fonts/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Ubuntu-Bold.eot');
  src: url('../fonts/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Ubuntu-Bold.woff') format('woff'), url('../fonts/Ubuntu-Bold.ttf') format('truetype'), url('../fonts/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: url('../fonts/Ubuntu-Light.ttf') format("opentype");
  font-display: swap;
}

body {
  font-family: 'Ubuntu' !important;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.custom-tooltip-black {
  /* width: 1000px !important; */
  font-family: Ubuntu;
  font-size: 11px;
  background: #ffffff !important;
  color: #717176 !important;
}

.logo_new {
  cursor: pointer !important;
}

.custom-header-bpost .custom-red-border {
  border-bottom: 1px;
  border-bottom: solid 3px #ef2637;
  font-size: 12px;
  display: inline-block;
  width: 100%;
  line-height: 18px;
  padding: 15px 0 12px;
}

.custom-header-bpost button.dropdown-toggle {
  background: none;
  border: none;
  float: right;
}

.custom-header-bpost .custom-caret {
  background: url('../images/Dark-Dropdown-Arrow.png');
  width: 7px;
  height: 4px;
  display: inline-block;
  margin-left: 5px;
  background-size: cover;
}

.custom-header-bpost a {
  width: 40px;
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: #5a5a5f;
}

.custom-header-bpost .dropdown a {
  font-size: 12px;
  color: #9c9c9f;
  font-family: 'Ubuntu';
}

.custom-header-bpost a:hover {
  text-decoration: none !important;
  background-color: transparent !important;
}

.custom-header-bpost ul li a:hover {
  color: #ef2637 !important;
  background: none;
}

.custom-header-bpost ul li a {
  width: 100%;
  text-align: center;
}

.custom-header-bpost ul li {
  width: 100%;
  text-align: center;
  background: #fff;
  padding-top: 15px;
}

.custom-header-bpost .custom_breadcrumb .breadcrumb>li+li:before {
  color: #5a5a5f;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url('../images/chevron-right.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 15px;
}

.custom-header-bpost .custom_breadcrumb img {
  width: auto;
  height: 34px;
  float: left;
  margin-right: 30px;
}

.custom-header-bpost .custom_breadcrumb ol {
  float: left;
  margin-top: 5px;
  background: transparent;
}

.custom-header-bpost .custom_breadcrumb .parcel-number a {
  width: 64px;
  height: 20px;
  line-height: 1.43;
  color: #5a5a5f;
}

.custom-header-bpost .custom_breadcrumb .Track-parcel a {
  width: 77px;
  height: 20px;
  line-height: 1.43;
  color: #ef2637;
  font-size: 12px;
  font-family: 'OpenSans';
  text-decoration: underline;
}

.custom-header-bpost li.breadcrumb-item.parcel-number {
  font-size: 12px;
  font-family: OpenSans;
  color: #404040;
  line-height: 1.33;
  font-stretch: normal;
}

.custom-header-bpost .custom_breadcrumb .Track-parcel a:hover {
  text-decoration: underline !important;
}

.custom-header-bpost.background {
  /* height: 118px;*/
  box-shadow: 0 2px 4px 0 #f3f3f3;
  background-color: #ffffff;
  margin-bottom: 15px;
}

.custom-header-bpost.background.custom-height {
  margin-bottom: 0px;
}

.custom-header-bpost .custom_breadcrumb {
  padding-top: 15px;
}

.custom_status_parent {
  margin-top: 25px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(158, 158, 209, 0.25);
  background-color: #ffffff;
  padding: 15px;
  border-bottom: 3px solid #ef2637;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.custom_status_parent .parceln {
  line-height: 1.43;
  color: #ef2637;
  font-family: Ubuntu;
  font-size: 14px;
}

.custom_status_parent .heading {
  font-size: 38px;
  line-height: 1.2;
  color: #404040;
  font-weight: 500;
  font-family: 'Ubuntu';
}

.custom_status_parent li {
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background: dodgerblue;
  margin: 0 1em;
  display: inline-block;
  color: white;
  position: relative;
}

.custom-progress {
  margin-top: 25px;
  display: flex;
  background: transparent !important;
}

.custom-progress.w3-light-grey,
.custom-progress.w3-hover-light-grey:hover,
.custom-progress.w3-light-gray,
.w3-hover-light-gray:hover {
  background: transparent !important;
  background-color: transparent !important;
}

.custom-progress div {
  width: 196px;
  height: 10px;
  border-radius: 5px;
  background-color: #dbdbdb !important;
}

.custom-progress div.active {
  background-color: #ef2637 !important;
}

.Estimated-delivery-d {
  line-height: 1.43;
  color: #5a5a5f;
}

.custom_status_parent .estimated-date {
  line-height: 1.43;
  color: #5a5a5f;
}

.custom_status_parent .delivery-date {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;
  color: #404040;
}

.custom_status_parent .date {
  margin-top: 15px;
}

.custom_status_parent .info {
  line-height: 1.43;
  color: #404040;
}

.custom_status_parent .payment,
.custom_status_parent .secure-code,
.custom_status_parent .payment-method,
.custom_status_parent .signature,
.custom_status_parent .opening-hours,
.custom_status_parent .get-direction {
  display: table;
  margin-bottom: 15px;
}

.custom_status_parent .payment p,
.custom_status_parent .secure-code p,
.custom_status_parent .payment-method p,
.custom_status_parent .signature p,
.custom_status_parent .get-direction p,
.custom_status_parent .opening-hours p {
  font-size: 20px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ef2637;
  display: table-cell;
}

.custom_status_parent .payment-method p {
  font-size: 14px;
}

.custom_status_parent .opening-hours p,
.custom_status_parent .get-direction p {
  font-size: 20px;
  width: 100%;
  cursor: pointer;
}

.custom_status_parent .payment img,
.custom_status_parent .secure-code img,
.custom_status_parent .signature img {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  display: table-cell;
}

.custom_status_parent .secure-code-info,
.custom_status_parent .due-payment {
  margin-top: 15px;
}

.custom_status_parent .secure-code-info a {
  color: #ef2637;
}

.custom_status_parent .secure-code-info p,
.custom_status_parent .due-payment p {
  font-family: Arial;
  font-size: 14px;
  line-height: 1.43;
  color: #404040;
}

.status-image-parent {
  position: absolute !important;
  top: 30%;
  right: 0%;
}

.status-image-parent .status-image img {
  width: 136px;
  height: 136px;
}

.custom-address-info .deliver-to {
  line-height: 1.43;
  color: #5a5a5f;
}

.custom-address-info .deliver-to-address {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;
  color: #404040;
}

.custom-address-info .deliver-to-pref {
  font-size: 20px;
  line-height: 1.2;
  color: #ef2637;
}

.custom-address-info .deliver-to-info {
  line-height: 1.43;
  color: #404040;
}

.custom-address-info {
  padding: 15px 0px;
  overflow: hidden;
}

.custom-neighbour-parent .custom-pref-two,
.custom-pickup-parent .custom-pref-two,
.custom-safe-parent .custom-pref-two {
  line-height: 1.43;
  color: #5a5a5f;
}

.custom-neighbour-parent .custom-pref-one,
.custom-pickup-parent .custom-pref-one,
.custom-safe-parent .custom-pref-one {
  font-size: 20px;
  line-height: 1.2;
  color: #404040;
  margin-top: 10px;
  margin-bottom: 5px;
}

.custom-neighbour-parent,
.custom-pickup-parent,
.custom-safe-parent {
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  position: relative;
  overflow: hidden;
  margin: 15px;
}

.custom-neighbour-parent,
.custom-pickup-parent,
.custom-safe-parent {
  margin-top: 0px;
}

.custom-neighbour-parent .custom-pref-sub-two,
.custom-pickup-parent .custom-pref-sub-two,
.custom-safe-parent .custom-pref-sub-two {
  text-align: right;
  position: absolute;
  top: 30%;
  right: 0%;
}

.custom-neighbour-parent img,
.custom-pickup-parent img,
.custom-safe-parent img {
  height: 21px;
  width: 21px;
}

.custom-submit-button {
  border-radius: 4px;
  padding: 15px;
  overflow: hidden;
  padding-top: 0px;
}

.custom-submit-button button {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  background-color: #ef2637;
  width: auto;
  height: 48px;
  border: none;
  border-radius: 4px;
  padding: 0px 15px;
}

i.more-less.glyphicon.glyphicon-chevron-right,
i.more-less.glyphicon.glyphicon-chevron-down {
  float: right;
  margin-top: 8px;
}

.opening-hours-body.show-div {
  display: none;
}

.opening-hours-body {
  margin-left: 35px;
}

.opening-hours-body tr td {
  font-family: 'Ubuntu-Regular';
  font-size: 14px;
  color: rgb(64, 64, 64);
  line-height: 1.8em;
}

.opening-hours-body tr.active td {
  font-family: 'Ubuntu-Medium';
}

table.timings {
  width: 100%;
}

table.timings tr td:nth-child(2) {
  text-align: right;
}

.wrapper .panel-title a:hover,
.wrapper .panel-title a:visited,
.wrapper .panel-title a:focus,
.wrapper .panel-title a:active {
  text-decoration: none;
}

.wrapper .panel-default>.panel-heading {
  background: transparent;
  border: none;
}

.wrapper .panel-group .panel {
  border: none;
  border-bottom: 0;
  box-shadow: none;
}

.wrapper .glyphicon-chevron-right:before {
  width: 16px;
  height: 25px;
  -webkit-mask-image: url('../images/chevron-right.png');
  mask-image: url('../images/chevron-right.svg');
  background-image: url('../images/chevron-right.png');
  background-repeat: no-repeat;
  padding-left: 30px;
  display: block;
  content: " ";
  background-color: transparent !important;
}

.notification-banner-mobile {
  /* width: 1440px; */
  background-color: #4195b9;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5%;
  height: auto;
  color: #ffffff;
  font-family: Ubuntu;
  font-size: 16px;
  text-emphasis: left;
}

.notification-banner {
  background-color: #4195b9;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 8%;
  height: auto;
  width: 100%;
  color: #ffffff;
  font-family: Ubuntu;
  font-size: 16px;
  text-emphasis: left;
}

@media screen and (max-width: 992px) {
  .notification-banner {
    padding-left: 2%;
  }
}

.payment-method .glyphicon-chevron-right:before,
.opening-hours .glyphicon-chevron-right:before,
.get-direction .glyphicon-chevron-right:before {
  width: 14px;
  height: 18px;
  /* -webkit-mask-image: url(../images/chevron-right.svg) no-repeat 50% 50%;
    mask-image: url(../images/chevron-right.svg);
    -webkit-mask: url(../images/chevron-right.svg) no-repeat 50% 50%; */
  background-image: url('../images/chevron-right.png');
  background-size: 100% 50%;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  /* background-color: #ef2637;  */
  -webkit-mask-size: contain;
}

.opening-hours .glyphicon-chevron-right:before,
.opening-hours .glyphicon-chevron-down:before,
.get-direction .glyphicon-chevron-right:before {
  height: 25px;
  float: right;
  -webkit-mask-size: contain;
}

.opening-hours .glyphicon-chevron-down:before {
  /* -webkit-mask: url('../images/chevron-down.svg') no-repeat 50% 50%; */
  background-image: url('../images/chevron-down.png');
  background-size: 90% 90%;
}

.custom_status_parent .payment-method img,
.custom_status_parent .get-direction img,
.custom_status_parent .opening-hours img {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  display: table-cell;
}

.custom_status_parent .get-direction img {
  height: 20px;
}

.wrapper .glyphicon-chevron-down:before {
  width: 16px;
  height: 25px;
  /* -webkit-mask-image: url('../images/chevron-down.svg');
    mask-image: url('../images/chevron-down.svg'); */
  background-image: url('../images/chevron-down.png');
  background-repeat: no-repeat;
  display: block;
  content: " ";
  padding-left: 27px;
  background-color: transparent !important;
}

.opening-hours .glyphicon-chevron-down:before {
  width: 14px;
  height: 18px;
  /* -webkit-mask-image: url('../images/chevron-down.svg') no-repeat 50% 50%;
    mask-image: url('../images/chevron-down.svg');
    -webkit-mask: url('../images/chevron-down.svg') no-repeat 50% 50%; */
  background-image: url('../images/chevron-down.png');
  background-size: 90% 90%;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  /*background-color: #ef2637;*/
  -webkit-mask-size: contain;
}

.wrapper .glyphicon {
  float: right;
}

.wrapper .panel-title a {
  font-size: 20px;
  line-height: 1.2;
  color: #ef2637;
}

.wrapper .panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top: none;
  padding-top: 0px;
  word-wrap: break-word;
}

.wrapper.center-block .panel-body {
  border-top: none !important;
  padding-top: 0;
}

.panel-sub-div {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #404040;
}

.wrapper .panel-group {
  margin-bottom: 0px;
}


/* chatbot css */

.open-button {
  color: white;
  border: none;
  cursor: pointer;
  bottom: 23px;
  float: right;
  width: 232px;
  height: 48px;
  border-radius: 4px;
  background-color: #da1021;
  margin-bottom: 15px;
}


/* The popup chat - hidden by default */

.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}


/* Add styles to the form container */

.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}


/* Full-width textarea */

.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}


/* When the textarea gets focus, do something */

.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}


/* Set a style for the submit/send button */

.form-container .btn {
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}


/* Add a red background color to the cancel button */

.form-container .cancel {
  background-color: red;
}


/* Add some hover effects to buttons */

.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}


/* chatbot css end */

.chatbot-bpost .background-image,
.custom_status_parent_mobile .background-image {
  width: 100%;
  height: 225px;
  background-image: url('../images/map.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.chatbot-bpost .background-image {
  width: auto;
}

.chatbot-bpost-body .custom_status_parent {
  margin-top: -32px;
  margin-bottom: 20px;
}

.chatbot-bpost-body .delivery-to,
.chatbot-bpost-body .delivery-by {
  color: #5a5a5f;
  margin-bottom: 0px;
}

.chatbot-bpost-body .delivery-to-add,
.chatbot-bpost-body .delivery-by-add,
.multiple-parcel-delivery .delivery-by-add,
.multiple-parcel-delivery .delivery-to-add {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.29;
  color: #404040;
}

.chatbot-bpost-body .status-image-parent {
  /* top:15%; */
  top: 0;
  margin-top: 5%;
}

.chatbot-bpost-body .status-image-parent.house {
  top: 0%;
  padding: 0px;
}

.status-image-parent.house .status-image img {
  width: 100%;
  height: 100%;
}

.status-image-parent.house .status-image {
  width: 100%;
  height: 100%;
}

.status-image {
  width: 100%;
  max-width: 150px;
  height: auto;
  max-height: 150px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 auto;
  background-position: center;
}

.status-image-lmg-icon {
  width: auto;
  /*max-width: 200px;*/
  height: auto;
  max-height: 200px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 auto;
  margin-top: -10px;
  background-position: center;
}

.status-image.desktop {
  position: absolute;
  top: 50%;
  display: inline-block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.custom_status_parent .get-direction {
  margin-bottom: 15px;
}

.step-1 .status-image {
  /* background-image: url('../images/icon_parcel maken_red RGB.png'); */
  background-image: url('../images/icon_parcel maken_red RGB_new.svg');
}

.step-2 .status-image {
  background-image: url('../images/processing_active.svg');
}

.step-3 .status-image {
  /* background-image: url('../images/out_for_delivery_irregularity_return.png'); */
  background-image: url('../images/out_for_delivery_active_new.svg');
}

.return-to-sender.step-1 .status-image {
  /* background-image: url('../images/preperationReturn.png'); */
  background-image: url('../images/preperationReturn_new.svg');
}

.return-to-sender.step-2 .status-image {
  /* background-image: url('../images/processingReturn.png'); */
  background-image: url('../images/processingReturn_new.svg');
}

.return-to-sender.step-3 .status-image {
  /* background-image: url('../images/deliveryReturn.png'); */
  background-image: url('../images/deliveryReturn_new.svg');
}

.return-to-sender.step-4 .status-image {
  /* background-image: url('../images/deliveredReturn.png'); */
  background-image: url('../images/deliveredReturn_new.svg');
}

.return-to-sender.step-4 .custom-progress.w3-light-grey.w3-round-xlarge {
  display: none;
}

.return-to-sender.step-1 button.open-button,
.return-to-sender.step-2 button.open-button,
.return-to-sender.step-3 button.open-button {
  display: none;
}

.international.step-2 .status-image {
  /* background-image: url('../images/deliveryReturn.png'); */
  background-image: url('../images/out_for_delivery_active_new.svg');
}

.international.step-3 .status-image {
  background-image: url('../images/processing_active.svg');
}

.international.step-4 .status-image {
  background-image: url('../images/icon_sorting center_red RGB.png');
}

.step-2 .custom-progress .w3-round-xlarge:nth-child(2) {
  background-color: #ef2637 !important;
}

.step-3 .custom-progress .w3-round-xlarge:nth-child(2),
.step-3 .custom-progress .w3-round-xlarge:nth-child(3) {
  background-color: #ef2637 !important;
}

.international.step-4 .custom-progress .w3-round-xlarge:nth-child(2),
.international.step-4 .custom-progress .w3-round-xlarge:nth-child(3),
.international.step-4 .custom-progress .w3-round-xlarge:nth-child(4) {
  background-color: #ef2637 !important;
}

.out-delivery .status-image {
  background-image: url('../images/icon_express delivery_red RGB.png');
}

.custom-progress .w3-container {
  width: 33%;
  float: left;
  margin-right: 15px;
}

.international .custom-progress .w3-container {
  width: 25%;
  float: left;
  margin-right: 15px;
}

.custom-progress .w3-container:last-child {
  margin-right: 0px;
}

.custom-progress .not-active {
  width: 0%;
}

.out-delivery .custom-progress .bg-red {
  width: 25%;
  background-color: #ef2637 !important;
}

.wrapper .panel-default .panel-title {
  border-bottom: 1px solid #ddd !important;
}

.wrapper.center-block .panel.panel-default {
  border: none;
}

.out-delivery .custom-progress .bg-danger {
  width: 10%;
  float: right;
  background-color: #979797 !important;
}

.out-delivery .custom-progress .progress {
  margin-bottom: 0;
  width: 100%;
}

.out-delivery .custom-progress {
  background: #f5f5f5 !important;
  height: auto;
}

.out-delivery .custom-progress div {
  margin-bottom: 0;
  width: 100%;
}

.out-delivery .out-delivery-name {
  font-family: 'Ubuntu-Medium';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: #404040;
  margin-bottom: 0px;
}

.out-delivery .out-delivery-status {
  font-family: Ubuntu;
  font-size: 14px;
  line-height: 1.43;
  color: #5a5a5f;
}

.out-delivery .out-delivery-image img {
  width: 40px;
  height: 40px;
}

.out-delivery .col-xs-2.out-delivery-image {
  padding-right: 0;
}

.out-delivery .col-xs-10.out-delivery-content {
  padding-left: 0;
}

.panel-group .panel-heading {
  padding: 15px 0px;
}

.StepProgress li.is-done:before,
.StepProgress li.done:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  text-indent: -6px;
  top: 5px;
  left: 0;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background: url('../images/icon_radioButton_selected.png') #fff no-repeat;
  background-size: contain;
}

.StepProgress li.current:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  text-indent: -6px;
  top: 5px;
  left: 0;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background: url('../images/icon_radioButton_disselected.png') #fff no-repeat;
  background-size: contain;
}

.StepProgress li.is-done:after {
  content: " ";
  width: 1px;
  background: #ef2637;
  height: 100px;
  padding: 0;
  position: absolute;
  display: inline-block;
  left: 7px;
  top: 20px;
}


/* .StepProgress li.done:after {
    content: " ";
    width: 1px;
    background: #979797;
    height: 50px;
    padding: 0;
    position: absolute;
    display: inline-block;
    left: 7px;
    top: 20px;
} */


/* .StepProgress li.current:after {
	content: " ";
    width: 1px;
    height: 60px;
    padding: 0;
    position: absolute;
    display: inline-block;
    left: 7px;
    top: 14px;
	background: #9d9d9d;
} */

.StepProgress li {
  position: relative;
  margin-bottom: 22px;
  margin-left: 0;
  /*     text-indent: 30px; */
}

.StepProgress li:last-child:after {
  content: '';
  height: 0px;
}

ul.innner-ul li {
  margin-bottom: 0;
}

ul.innner-ul li.first-li {
  font-size: 18px;
  line-height: 1.33;
  color: #404040;
}

.StepProgress li.StepProgress-item {
  display: none;
}

.StepProgress li.StepProgress-item.is-done,
.StepProgress li.StepProgress-item.done,
.StepProgress li.StepProgress-item.current {
  display: block;
}

.StepProgress li.current:first-child {
  display: block;
}

ul.innner-ul li.second-li {
  font-family: OpenSans;
  font-size: 14px;
  line-height: 1.43;
  color: #404040;
}

li.current ul.innner-ul li.second-li {
  display: none;
}

ul.innner-ul li:after,
ul.innner-ul li:before {
  content: "";
  display: none;
}

.innner-ul {
  height: 80px;
  list-style: none;
  padding-left: 20px;
}

.StepProgress {
  list-style: none;
  padding: 0;
}

.panel-body.sub {
  padding: 0;
}

.panel-body.sub .panel-title {
  border-bottom: none !important;
}

.panel-body.sub p.panel-sub-div {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: #404040;
}

.panel-group .panel-heading+.panel-collapse>.panel-body {
  padding-left: 0px;
}

.panel-body.sub .glyphicon-chevron-right:before,
.panel-body.sub .glyphicon-chevron-down:before {
  background-color: #5a5a5f;
}

p.panel-parent-div {
  margin-bottom: 0;
}

.general-links-footer.secondary-link-group {
  text-align: center;
}

.general-links-footer.secondary-link-group a,
.general-links-footer.secondary-link-group span {
  color: #404040;
  font-size: 12px;
}

.chatbot-bpost-body .panel-group .panel-heading {
  padding: 15px 0px;
}

.cust_ref span,
.parcel_weight span,
.parcel_dimenion span {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: normal;
  color: #5a5a5f;
}

.panel-heading.last .panel-sub-div {
  font-family: OpenSans;
  font-size: 14px;
  color: #5a5a5f;
  font-weight: normal !important;
  border-bottom: 1px solid #ffffff;
}

.panel-heading.last {
  border-bottom: 1px solid #ddd !important;
}

.page {
  display: none;
}

.page-active,
.page-active-desktop {
  display: block;
}

#signature_detail .jumbotron,
#signature_detail_1 .jumbotron {
  background: transparent;
  border: 1px solid rgb(151, 151, 151);
  padding: 15px;
  border-radius: 0;
}

#signature_detail .panel-body,
#signature_detail_1 .panel-body {
  padding-right: 0px;
}

#signature_detail li.first.disabled,
#signature_detail li.prev.disabled,
#signature_detail li.first,
#signature_detail li.prev,
#signature_detail li.next,
#signature_detail li.last,
#signature_detail_1 li.first.disabled,
#signature_detail_1 li.prev.disabled,
#signature_detail_1 li.first,
#signature_detail_1 li.prev,
#signature_detail_1 li.next,
#signature_detail_1 li.last {
  display: none !important;
}

#signature_detail #pagination-demo,
#signature_detail_1 #pagination-demo {
  margin: 0 auto;
}

#signature_detail .pagination-ul,
#signature_detail_1 .pagination-ul {
  text-align: center;
}

.pagination-ul a.page-link {
  background: transparent;
  background-color: transparent;
  border: none;
}

.pagination-ul a.page-link {
  background: rgb(219, 219, 219);
  height: 0px;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 12px !important;
  margin-left: 10px;
  text-indent: -9999px;
}

.pagination-ul .active a.page-link {
  background: #ef2637 !important;
}

.signature-1 {
  width: 136px;
  height: 146px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 auto;
  background-image: url('../images/signature.png');
}

.jumbotron p {
  font-family: 'Roboto-Regular';
  font-size: 12px !important;
  margin-bottom: 5px !important;
}

.custom-header-bpost .image-container {
  height: 280px;
  background: url("../images/header-visu.jpg") 100% 0 no-repeat;
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)), url("../images/header-visu.jpg");
}

.custom-header-bpost .image-container-content {
  padding: 26px 15px 0;
}

.custom-header-bpost .image-container h1 {
  margin-top: 45px;
  font-size: 36px;
  font-weight: 300;
}

.custom-header-bpost .logo {
  width: 109px;
  height: 60px;
  /*background: url('../images/logo.png') no-repeat;*/
  cursor: pointer;
}

.out-delivery .panel-group .panel-heading+.panel-collapse>.panel-body,
.international .panel-group .panel-heading+.panel-collapse>.panel-body,
.inner-page .panel-group .panel-heading+.panel-collapse>.panel-body {
  padding-left: 15px;
}

@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);

.homepage_parent_div .image-container {
  height: 180px;
  background: url("../images/header-visu.png") 100% 0 no-repeat;
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)), url("../images/header-visu.png");
}

.homepage_parent_div .image-container-content {
  padding: 26px 15px 0;
}

.homepage_parent_div .image-container h1 {
  margin-top: 0px;
  font-size: 36px;
  font-weight: 300;
}

.logo_header {
  cursor: pointer;
}

.search-box-container {
  width: 100% !important;
  border: 15px solid #e9e9ea;
  border-radius: 4px;
  background-color: #e9e9ea;
  margin-bottom: 20px;
}

.search-box-container .form-control:first-child {
  border-radius: 4px !important;
  box-shadow: none;
  border: 1px solid #fff;
}

.search-box-container .input-group-addon {
  padding: 0 0 0 10px;
  background-color: transparent;
  border: none;
}

.search-box-container .btn {
  padding-top: 7px;
  padding-bottom: 7px;
  background: #ef2637;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border: 0;
  width: auto;
  height: auto;
}

.search-box-container .form-control:first-child {
  width: 100% !important;
}

.footer {
  padding: 0 15px 15px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.general-links-footer .general-links-footer-list .row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.custom-back-url {
  margin-top: 15px;
}

.custom-back-url a {
  cursor: pointer;
  color: #83bbd2;
  letter-spacing: 0;
}

.custom-header-bpost.visible-xs .mobile-header {
  /*width: 50px;
    height: 30px;*/
  margin-left: 15px;
}

.homepage_parent_div input.postal_code {
  border-radius: 4px !important;
  box-shadow: none;
  border: 1px solid #fff;
  width: 29% !important;
  margin-left: 2%;
  padding: 6px 10px;
}

.homepage_parent_div input.barcode_code {
  width: 69% !important;
  padding: 6px 12px;
}

.search-box-container {
  border: 15px solid #e9e9ea;
  border-radius: 4px;
  background-color: #e9e9ea;
  margin-bottom: 20px;
}

.search-box-container .form-control:first-child {
  border-radius: 4px !important;
  box-shadow: none;
  border: 1px solid #fff;
}

.search-box-container .input-group-addon {
  padding: 0 0 0 10px;
  background-color: transparent;
  border: none;
}

.content_homepage h3 {
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  margin-bottom: 25px;
}

.content_homepage .step-strong {
  color: #5a5a5f;
  font-weight: 700;
}

.content_homepage p,
.content_homepage h3 .h3-extra,
.content_homepage ul li {
  color: #a0a2a3;
}

.content_homepage .custom-answer-color-black p {
  color: #000000;
}

.content_homepage .custom-answer-color-black ul {
  color: #000000;
}

.content_homepage .custom-answer-color-black ul li {
  color: #000000;
}

input#search-input0.mobile[_ngcontent-ool-c4] {
  width: 100% !important;
}

input#search-input1.mobile[_ngcontent-ool-c4] {
  width: 100% !important;
}

input#search-input2.mobile[_ngcontent-ool-c4] {
  width: 100% !important;
}

input#search-input3.mobile[_ngcontent-ool-c4] {
  width: 100% !important;
}

input.mobile {
  width: 100% !important
}

#content-container-error {
  border-radius: 4px;
  border: solid 1px #bce8f1;
  background-color: #d9edf7;
  padding: 15px;
  text-align: left;
  margin-top: 15px;
}

#content-container-error p {
  color: #a0a2a3;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

#content-container-error h3 {
  color: #31708f;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 5px;
}

.modal-backdrop {
  position: fixed;
  background-color: #000;
  width: 100vw;
  height: 100vh;
}

#track_popup .modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

#track_popup .modal-content {
  width: 100%;
}

#track_popup .modal-header h5 {
  font-size: 20px;
  line-height: 1.2;
  color: #ef2637;
}

#track_popup .close span {
  position: absolute;
  top: 15px;
  right: 15px;
}

#track_popup .modal-body p {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #5a5a5f;
  margin-bottom: 0px;
}

#track_popup .modal-header {
  border-bottom: 0px;
}

#track_popup .modal-footer {
  border-top: 0px;
}

#track_popup .remove_padding_left {
  padding-left: 0px;
}

#track_popup .remove_padding_right {
  padding-right: 0px;
}

#track_popup .modal-footer button {
  border-radius: 4px;
  background-color: #ef2637;
  width: 100%;
  color: white;
  border: none;
  padding: 6px 12px;
}

#track_popup .modal-footer input {
  border-radius: 4px;
  box-shadow: inset 0 0 7px 0 rgba(90, 90, 95, 0.3);
  background-color: #ffffff;
}

#track_popup .modal-footer #content-container-error {
  margin-top: 15px;
}

.out-delivery .wrapper .panel-heading,
.international .wrapper .panel-heading {
  padding-left: 15px;
}

.multiple_barcode .custom-submit-button {
  padding-top: 15px;
  float: right;
  padding-bottom: 0px;
}

.multiple_barcode .expand {
  text-align: center;
}

.multiple_barcode .expand .custom-submit-button {
  float: none;
}

.show_desktop_barcode {
  display: none;
}

.show_desktop_barcode.active {
  display: block;
}

.show_mobile_barcode {
  display: none;
}

.show_mobile_barcode.active {
  display: block;
}

.multiple_barcode .custom_status_parent,
.multiple_barcode .custom_status_parent_mobile {
  cursor: pointer;
}

.preflinkref {
  color: #ef2637;
}

.custom-multiple-parcel p {
  line-height: 1.43;
  color: #404040;
  font-family: Ubuntu;
  font-size: 20px;
  margin-bottom: 0px;
  padding-left: 30px;
}

.custom-view-detail-a-parent {
  text-align: right;
}

.custom-multiple-parcel .custom-submit-button button {
  float: right;
}

.multiple_barcode .status-image-parent {
  top: 25%;
}

.multiple_barcode .custom-multiple-parcel {
  margin-top: 30px;
}

.multiple_barcode .custom_status_parent {
  margin-top: 15px;
}

.customer-note-parent {
  overflow: hidden;
  margin: 15px 0px 0px 0px !important;
  background-color: #f3f3f3;
  padding: 15px 0px;
}

.custom-view-detail-a,
.custom-view-detail-a:hover {
  color: #ef2637;
  text-decoration: underline;
}

.custom-deliver-kariboo {
  margin-bottom: 15px;
}

.customer-note-parent p.title-customer-note {
  color: #ef2637;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: normal;
}

.customer-note-parent p.content-customer-note {
  line-height: 1.43;
  color: #404040;
}

.custom-deliver-kariboo p.deliver-to-get-direction,
.custom-deliver-kariboo p.deliver-to-open-hours {
  font-weight: bold;
}

.custom-deliver-kariboo .map-image {
  margin-bottom: 15px;
}

.custom-deliver-kariboo .map-image img {
  width: 100%;
}

.custom-deliver-kariboo .opening-hours-body.show-div {
  display: block;
}

.custom-deliver-kariboo .opening-hours-body tr.active td,
.custom-deliver-kariboo .opening-hours-body tr td {
  font-family: Ubuntu;
}

.multiple_barcode .count_shipment_p {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2;
  color: #404040;
}

.multiple_barcode .row.count_shipment {
  margin-top: 30px;
  padding: 0px 30px;
}

.row.track_trace_zip_code {
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(90, 90, 95, 0.25);
  background-color: #ffffff;
  padding: 15px;
  position: relative;
  overflow: hidden;
  margin: 15px 0px;
}

.track_trace_zip_code .deliver-to {
  line-height: 1.2;
  letter-spacing: normal;
  color: #ef2637;
  font-size: 20px;
}

.track_trace_zip_code button {
  border-radius: 4px;
  background-color: #ef2637;
  width: 100%;
  color: white;
  border: none;
  padding: 6px 12px;
}

.track_trace_zip_code input,
.track_trace_zip_code button {
  height: 48px;
}

.content_homepage_faq_listing.content-block .sub-content-block h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.content_homepage_faq_listing.content-block .sub-content-block {
  padding-top: 20px;
  padding-bottom: 20px;
}

.content_homepage_faq_listing .faq-list .faq-category-level1 .category-title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}


/* .faq-list .faq-category-level1 {
    margin-top: 35px;
} */

.content_homepage_faq_listing .wrapper .panel-parent-div {
  display: flex;
}

.content_homepage_faq_listing i.glyphicon.glyphicon-plus,
.content_homepage_faq_listing i.glyphicon.glyphicon-minus {
  float: left;
  height: 20px;
  font-size: 13px;
  width: 18px;
  position: relative;
}

.content_homepage_faq_listing .wrapper .panel-title a {
  color: #333;
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 0;
}

.wrapper.faq-listing .panel-default .panel-title {
  border-bottom: 0px !important;
}

.wrapper.faq-listing .panel-group .panel-heading {
  padding: 10px 0px;
}

.wrapper.faq-listing .panel-body-title {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

.wrapper.faq-listing .panel-body {
  padding-left: 18px !important;
}

.wrapper.faq-listing ul {
  padding-left: 0px;
}

.wrapper.faq-listing ul li {
  list-style: none;
  margin-top: 7px;
  margin-left: 15px;
  margin-right: 0;
  color: #83bbd2;
  font-weight: 400;
  margin-bottom: 7px;
}

.custom-header-bpost.faq-header .image-container {
  height: 200px;
}

.homepage_parent_div a {
  color: #83bbd2;
}

.faq-listing p {
  color: #333333;
}

.wrapper .panel {
  box-shadow: none;
}

.custom-remove-padding-parent {
  padding: 0px !important;
}

.custom-remove-padding {
  padding: 0px !important;
  padding-right: 15px !important;
}

.status-image-parent-modified {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.custom-sub-faq .panel-heading {
  padding: 5px 0px !important;
}

.step-location {
  color: #83bbd2;
  font-weight: 700
}

.mat-checkbox-label {
  white-space: pre-line;
  font-family: Ubuntu-Regular;
  font-size: 14px;
  color: #404040;
}

.mat-checkbox .mat-checkbox-frame {
  border-color: #ef2637;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ef2637 !important;
}

.mat-checkbox-inner-container {
  margin-top: 6px !important;
}

.mat-checkbox-ripple {
  display: none;
}

@media (min-width: 576px) {
  #track_popup .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  #track_popup .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media only screen and (max-width: 767px) {
  .custom-remove-padding-parent {
    padding: 15px !important;
  }

  .custom-remove-padding {
    padding-right: 0px !important;
  }

  .track_trace_zip_code .remove_padding_left {
    padding-left: 0px;
  }

  .track_trace_zip_code .remove_padding_right {
    padding-right: 0px;
  }

  .multiple_barcode .row.count_shipment {
    padding: 0px;
  }

  .custom-view-detail-a,
  .custom-view-detail-a:hover {
    padding-right: 0px;
  }

  .custom-multiple-parcel p {
    padding-left: 0px;
  }

  .customer-note-parent,
  .row.track_trace_zip_code {
    margin: 0px -15px;
    margin-bottom: 30px;
    padding: 15px;
  }

  .multiple_barcode .custom-submit-button {
    padding: 15px 15px;
    float: right;
    padding-bottom: 0px;
    width: 100%;
    padding-top: 0px;
  }

  .custom-submit-button button {
    width: 100%;
    margin-bottom: 15px;
  }

  .status-image-parent .status-image img {
    float: right;
  }

  .Fill-5 {
    width: 17px;
    height: 18px;
    object-fit: contain;
    float: right;
  }

  .custom_status_parent_mobile.hidden-lg {
    padding: 15px 30px;
  }

  .status-image {
    margin: 0 auto;
    text-align: center;
  }

  .status_image {
    width: 108.6px;
    height: 108.6px;
  }

  .custom_status_parent .parceln {
    font-family: Arial;
  }

  .custom_status_parent .heading {
    font-size: 30px;
    line-height: 1.33;
    color: #404040;
  }

  .custom_status_parent .delivery-date {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    color: #404040;
  }

  .custom_status_parent .estimated-date {
    font-family: Arial;
    font-size: 14px;
  }

  .custom_status_parent .info {
    font-size: 14px;
    font-family: Arial;
  }

  .custom_status_parent .payment img,
  .custom_status_parent .secure-code img,
  .custom_status_parent .payment-method img,
  .custom_status_parent .signature img,
  .custom_status_parent .get-direction img,
  .custom_status_parent .opening-hours img {
    width: 15px;
    height: 15px;
  }

  .custom_status_parent .get-direction img {
    height: 20px;
  }

  .custom_status_parent_mobile {
    padding: 15px 0px;
  }

  .multiple_barcode .custom_status_parent_mobile {
    padding: 15px 0px !important;
  }

  .custom_status_parent_mobile.chatbot-bpost-body {
    padding: 0px;
  }

  .chatbot-bpost-body .custom_status_parent {
    margin-top: -75px;
    margin-bottom: 20px;
  }

  .chatbot-bpost-body .cursor_pointer {
    top: 0;
    width: 100%;
    position: absolute;
  }

  .custom-address-info .row .col-xs-12 {
    padding: 0px;
  }

  .custom-neighbour-parent,
  .custom-pickup-parent,
  .custom-safe-parent {
    margin-left: 0px;
    margin-right: 0px;
  }

  .cursor_pointer img {
    cursor: pointer;
  }

  .open-button {
    margin: 0 auto;
    float: none;
    width: 100%;
  }

  .custom-submit-button {
    padding: 0px;
  }

  .custom-header-bpost .custom-red-border {
    padding-right: 15px;
  }

  .custom_status_parent_mobile.house .background-image {
    background-size: cover;
    background-image: url('../images/delivery_image.png');
  }

  .custom-address-info {
    padding: 15px;
  }

  .footer {
    background-color: #e9e9ea;
    padding-top: 15px;
  }

  .custom-header-bpost .image-container {
    background: url('../images/header-img-mobile.jpg') 100% 0 no-repeat;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)), url('../images/header-img-mobile.jpg');
    height: 144px;
  }

  .homepage_parent_div input.barcode_code {
    width: 59% !important;
  }

  .homepage_parent_div input.postal_code {
    width: 39% !important;
  }

  .search-box-container .btn,
  .homepage_parent_div input {
    height: 40px;
  }

  .homepage_parent_div .image-container h1 {
    font-size: 20px;
    margin-top: 60px;
  }

  .custom_search_p {
    margin-top: 0px;
  }

  .footer {
    background-color: #e9e9ea;
    padding-top: 15px;
  }
}
